<script>
	import GlobalVue from '@helper/Global.vue';
	export default { extends: GlobalVue, };
</script>
<style>
	#loader {
		position: relative;
		width: fit-content;
	}
	.loader__bg{
		width: 150px;
		transform: rotate(-45deg) translate(33%, -33%);
		opacity:0;
		animation: bgMove 1.5s forwards;
	}
	.loader__letter {
		width: 150px;
		position: absolute;
		left:0;
		top:0;
		opacity:0;
		animation:letterShow 1s forwards;
	}
	.loader__letter.loader__letter--h{animation-delay: .5s;}
	.loader__letter.loader__letter--i{animation-delay: .75s;}
	.loader__letter.loader__letter--l{animation-delay: 1s;}
	.loader__letter.loader__letter--o{animation-delay: 1.25s;}
	.loader-aligner{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
	}
	#loader span{
		background-color:#26326f;
		display:block;
		width:8px;
		height:8px;
		border-radius:16px;
		animation:loading 3.5s infinite;
		margin-top:16px;
	}
	@keyframes letterShow{
		from{
			opacity:0;
		}
		to{
			opacity:1;
		}
	}
	@keyframes bgMove{
		from{
			transform: rotate(-45deg) translate(33%, -33%);
			opacity:0;
		}
		to{
			transform: rotate(0deg) translate(0%, 0%);
			opacity:1;
		}
	}
	@keyframes loading {
    0% {
       width: 8px;
       transform: translateX(0px);
    }

    40% {
       width: 100%;
       transform: translateX(0px);
    }

    80% {
       width: 8px;
       transform: translateX(134px);
    } 

    90% {
       width: 100%;
       transform: translateX(0px);
    }

    100% {
       width: 8px;
       transform: translateX(0px);
    }
 }
</style>
<template>
	<section class='loader-aligner'>
	  	<section id='loader'>
	  		<img :src="assets('fo_images','/loader-set/bg.png')" class='loader__bg' alt=''>
	  		<img :src="assets('fo_images','/loader-set/h.png')" class='loader__letter loader__letter--h' alt=''>
	  		<img :src="assets('fo_images','/loader-set/i.png')" class='loader__letter loader__letter--i' alt=''>
	  		<img :src="assets('fo_images','/loader-set/l.png')" class='loader__letter loader__letter--l' alt=''>
	  		<img :src="assets('fo_images','/loader-set/o.png')" class='loader__letter loader__letter--o' alt=''>
			<span></span>
	  	</section>
  </section>
</template>